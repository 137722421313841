import { cable } from "@hotwired/turbo-rails"

let _processor = null
let _publisherId = null

let _savedCaretPosition = null
let _currentManual = null;
let _previousPartial = null;
let _pauseUpdates = null;

class Processor {
  host = null
  stream = null
  wsClient = null
  script = null

  get connected() {
    return this.wsClient && this.wsClient.readyState === WebSocket.OPEN
  }

  start = (stream, host) => {
    if (this.connected) {
      return console.warn('Already connected...?')
    }
    this.stream = stream
    this.host = host
    this.stream.oninactive = () => {
      console.log('Stream ended')
    }
    this.connect()
    this.buildAudioContext()
  }

  stop = () => {
    if (this.stream) {
      this.stream.getTracks().forEach(t => t.stop())
      this.stream = null
    }
    if (this.script) {
      this.script.disconnect()
      this.script.onaudioprocess = null
      this.script = null
    }
    if (this.connected) {
      const payload = JSON.stringify({
        method: 'end',
        userId: 'userId',
      })
      this.wsClient.send(payload)
      this.wsClient.close()
    }
  }

  connect = () => {
    this.wsClient = new WebSocket(this.host)
    this.wsClient.binaryType = 'arraybuffer'
    var capContextId = document.getElementById('capContext').value;
    var captioningLanguage = document.getElementById('captioningLanguage').value;
    this.wsClient.onopen = () => {
      console.debug('Socket open!!')
      var payloadOptions = {
        method: 'start',
        userId: 'userId'
      }
      if (capContextId) {
        payloadOptions.capContextId = capContextId;
      }
      if (captioningLanguage) {
        payloadOptions.captioningLanguage = captioningLanguage;
      }
      console.log('payloadOptions', payloadOptions)
      const payload = JSON.stringify(payloadOptions)
      this.wsClient.send(payload)
    }
    this.wsClient.onclose = () => {
      console.debug('Socket close!')
      this.stop()
    }
    this.wsClient.onmessage = (message) => {
      try {
        const { isFinal, text } = JSON.parse(message.data)
        handlePartial(isFinal, text)
      } catch (error) {
        console.error('Node-ASR Error', error)
      }
    }
  }

  buildAudioContext = () => {
    var audioCtx = new AudioContext()
    this.script = audioCtx.createScriptProcessor(4096, 1, 1)
    this.script.onaudioprocess = (event) => {
      var input = event.inputBuffer.getChannelData(0) || new Float32Array(4096)
      for (var idx = input.length, newData = new Int16Array(idx); idx--;) {
        newData[idx] = 32767 * Math.min(1, input[idx])
      }
      if (this.connected) {
        this.wsClient.send(newData)
      }
    }
    var mic = audioCtx.createMediaStreamSource(this.stream)
    mic.connect(this.script)
    this.script.connect(audioCtx.destination)
  }
}

function handlePartial(isFinal, text) {
  if (isFinal) {
    formSubmit({command: 'final', body: text})
  } else {
    if (_previousPartial !== text) {
      // reduce update frequency
      debouncedPartial(text)
    } 
  }
}

function ready(callback) {
  if (document.readyState != 'loading') {
    callback();
  } else if (document.addEventListener) {
    document.addEventListener('DOMContentLoaded', callback);
  } else {
    document.attachEvent('onreadystatechange', function() {
      if (document.readyState != 'loading') {
        callback();
      }
    });
  }
}

const toggleMicrophone = async function (btn, ignorestart) {
  const activeBtnClass = "btn-audio-active";
  const inactiveBtnClass = "btn-audio-inactive";
  const voiceOffText = '<i class="fa fa-microphone"></i> Start Captioning';
  const voiceOnText = '<i class="fa fa-microphone-slash"></i> Stop Captioning';

  if (_processor == null) {
    _processor = new Processor();
  }

  try {
    if (_processor.connected) {
      _processor.stop()
      // Remove "active" class to the btn
      btn.classList.remove(activeBtnClass);
      btn.classList.add(inactiveBtnClass);
      btn.innerHTML = voiceOffText;
      document.getElementById('deviceList').disabled = false
      document.getElementById('capContext').disabled = false
      document.getElementById('captioningLanguage').disabled = false
      formSubmit({command: 'stop_audio'});
    } else {
      
      if (ignorestart) {
        console.log('ignoring ASR start');
      } else {
        // Create the audio stream and start the processor
        const audioConstraints = document.getElementById('deviceList').value;
        console.log("AUDIO DEVICE", audioConstraints)
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: { deviceId: audioConstraints },
          video: false
        })
        _processor.start(stream, globalWssUrl)
        // Add "active" class to the btn
        btn.classList.remove(inactiveBtnClass);
        btn.classList.add(activeBtnClass);
        btn.innerHTML = voiceOnText;
        document.getElementById('deviceList').disabled = true
        document.getElementById('capContext').disabled = true
        document.getElementById('captioningLanguage').disabled = true
        formSubmit({command: 'start_audio'});
      }
    }
  } catch (error) {
    console.error('getUserMedia error', error)
  }
  if (!ignorestart) {
    //toggleAudioBroadcast();
  }
}

const listDevices = async function () {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false })
    stream.getTracks((t) => t.stop())
    const devices = await navigator.mediaDevices.enumerateDevices()
    const audioDevices = devices.filter(device => device.kind === 'audioinput')

    var select = document.getElementById('deviceList');
    audioDevices.forEach(element => {
      var opt = document.createElement('option');
      opt.value = element.deviceId;
      opt.text = element.label;
      select.add(opt);
    });
  } catch (error) {
    console.error('Error', error)
  }
}

function formSubmit(body) {
  var token = document.getElementsByName('csrf-token')[0].content
  var formData = new FormData();
  formData.append('authenticity_token', token)
  formData.append('publisher_id', _publisherId || null);
  for (const [key, value] of Object.entries(body)) {
    formData.append(key, value)
  }

  return fetch(globalSubmitUrl, {
    body: formData,
    method: "post"
  })
  .then((response) => response.json())
    .then((responseData) => {
      return responseData;
    })
    .catch(error => console.warn(error));
}

function scrollToBottom() {
  if (document.getElementById('autoScroll').checked) {
    // var objDiv = document.getElementById("stream-wrapper");
    // objDiv.scrollTop = objDiv.scrollHeight;
    document.getElementById('bottom-anchor').scrollIntoView({ behavior: "smooth" });
    // console.log('trying to scroll')
    // window.scrollTo(0,document.body.scrollHeight);
  }
}
function handleUpdate(data) {
  switch(data.command) {
    case 'partial':
      // do not display manual for myself
      if (data.mode == 'manual') {
        if (data.publisher_id != _publisherId) {
          document.getElementById('manual').innerHTML = data.body;
          scrollToBottom();
        }
      } else {
        handleReceivePartial(data)
      }
      break;
    case 'final':
      handleFinal(data);
      break;
    case 'update':
      handleSentenceUpdate(data)
      break;
    case 'insert':
      handleInsert(data);
      break;
    case 'delete':
      handleDelete(data);
      break;
    case 'start_stream':
      handleStartStream(data);
      break;
    case 'start_audio':
      handleStartAudio(data);
      break;
    case 'title':
      handleTitle(data);
      break;
    case 'chat':
      handleChat(data);
      break;
  }
  if (document.getElementById('autoScroll').checked) {
    scrollToBottom();
  }
}

function handleReceivePartial(data) {
  if (mode == "publish") {
    if (_pauseUpdates) {
      console.log('skipping update because of editing')
    } else {
      document.getElementById('partial').innerHTML = data.body;
    }
  } else {
    document.getElementById('partial').textContent = data.body;
  }
}

function setStatus(text) {
  if (mode == "publish") {
    console.log('status', text)
    // document.getElementById('status').innerHTML = text;
    // setTimeout(function() {
    //   document.getElementById('status').innerHTML =  '';
    // }, 2000);
  }
}

function handleStartStream(data) {
  if (mode == "publish") {
    if (data.publisher_id != _publisherId) {
      // someone else has started streaming
      setStatus("Someone else has started streaming text");
      muteAudio();
      var btn = document.getElementById('startCaption');
      toggleMicrophone(btn, true)
    }
  }
}

function handleStartAudio(data) {
  console.log(data, _publisherId)
  if (mode == "publish") {
    if (data.publisher_id != _publisherId) {
      // someone else has started sending audio
      setStatus("Someone else has started broadcasting audio");
      muteAudio();
      var btn = document.getElementById('startCaption');
      toggleMicrophone(btn, true)
    }
  }
}

function handleTitle(data) {
  if (data.publisher_id != _publisherId) {
    if (mode == "publish") {
      docTitle.value = data.title;
    } else {
      docTitle.innerText = data.title;
    }
  }
}

function handleSentenceUpdate(data) {
  if (mode == "stream") {
    if (_translationLanguage != 'default' ) {
      console.log('translation needed', data.id, _translationLanguage )
      formSubmit({command: 'translate', sentence_id: data.id, language: _translationLanguage })
      .then(response => {
        console.log(response)
        if (response.status == 'OK') {
          var target = document.getElementById('sentence_' + response.id + '_' + _translationLanguage);
          if (target) {
            target.innerHTML = response.body;
          } else {
            document.getElementById('sentence_' + response.id ).innerHTML = response.body;
          }
        } else {
          window.location = location.href.replace(location.search, '');
        }
      });
    } else {
      var element = 'sentence_' + data.id;
      document.getElementById(element).innerHTML = data.body;
    }
  } else {
    // ignore my own updates
    if (data.publisher_id != _publisherId) {
      var element = 'sentence_' + data.id;
      document.getElementById(element).innerHTML = data.body;
    }
  }
}

function handleFinal(data) {
  if (data.mode == 'manual') {
    document.getElementById('manual').textContent = '';
  } else {
    document.getElementById('partial').textContent = '';
  }

  if (mode == "stream") {
      if (_translationLanguage != 'default') {
      console.log('translation needed', data.id, _translationLanguage )
      formSubmit({command: 'translate', sentence_id: data.id, language: _translationLanguage })
      .then(response => {
        console.log(response)
        if (response.status == 'OK') {
          createAndAppend(data.id + '_' + _translationLanguage, response.body);
        } else {
          console.log('refresh in processor line 323')
          window.location = location.href.replace(location.search, '');
        }
      });
    } else {
      createAndAppend(data.id, data.body);
    }
  } else {
    createAndAppend(data.id, data.body);
  }
}

function createAndAppend(id, body) {
  var elem = createSentenceElement(id, body);
  const target = document.getElementById('partial');
  target.parentNode.insertBefore(elem, target);
}

function createSentenceElement(id, body) {
  var elem = document.createElement('div');
  elem.id = "sentence_" + id
  elem.innerHTML = body

  if (mode == "publish") {
    elem.className = "sentence-input"
    if (isEditable) {
      elem.contentEditable = true
    }
  } else if (mode == "stream") {
    elem.className = "sentence-stream"
  }

  return elem;
}

function handleInsert(data) {
  if (mode == "stream" && _translationLanguage != 'default') {
    var elem = createSentenceElement(data.id + '_' + _translationLanguage, '');
  } else {
    var elem = createSentenceElement(data.id, '');
  }
  var after = document.getElementById("sentence_" + data.after);

  if (!after && mode == "stream" && _translationLanguage != 'default' ) {
    var after = document.getElementById("sentence_" + data.after+ '_' + _translationLanguage);
  }

  after.after(elem)
  if (data.publisher_id == _publisherId) {
    // focus it only if it is mine
    var current = document.activeElement
    var prev = current.previousElementSibling

    console.log('current', current.id, 'going to', data.id, 'prev is', prev.id)
    if (prev.id == elem.id) {
      // do not go backwards
      console.log('NOT GOING BACKWARDS')
    } else {
      elem.focus();
    }
  }
}

function handleDelete(data) {
  var elm = document.getElementById("sentence_" + data.id);
  if (elm) {
    elm.remove();
  }
}

function addLiveEventListeners(selector, event, handler){
  // console.log('adding live event listener', selector, event, handler)
  document.querySelector("body").addEventListener(
       event, function(evt){
        var target = evt.target;
        while (target != null){
          var isMatch = target.matches(selector);
          if (isMatch){
              handler(target, evt);
              return;
          }
          target = target.parentElement;
        }
      }, true
  );
}

function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

function createUUID(){
  let dt = new Date().getTime()
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = (dt + Math.random()*16)%16 | 0
      dt = Math.floor(dt/16)
      return (c=='x' ? r :(r&0x3|0x8)).toString(16)
  })
  
  return uuid
}
window.createUUID = createUUID;

var handleKeyDown = function (target, evt) {
  var sentence_id = target.id.split("_")[1];

  if (isArrowMovement(evt.key)) {
    _savedCaretPosition = getCaretLocation(evt.key, target)
  }

  if (evt.key === "Backspace" && target.textContent == "") {
    // place caret at end of previous input
    placeCaretAtEnd(target.previousElementSibling);
    evt.preventDefault();
    // broadcast remove
    formSubmit({command: 'delete', sentence_id: sentence_id })
  }
  
  if (evt.key === 'Enter' && !evt.shiftKey) {
    evt.preventDefault();
    // create a new sentence but AFTER the current one
    formSubmit({command: 'insert_after', sentence_id: sentence_id })
  }
}

var handleKeyUp = function (target, evt) {
  if (isArrowMovement(evt.key)) {
    if (evt.ctrlKey) {
      switch(evt.key) {
        case 'ArrowUp':
          moveToPrevious(target);
          break;
        case 'ArrowDown':
          moveToNext(target);
          break;
      }
    } else {
      if (_savedCaretPosition == getCaretLocation(evt.key, target)) {
        switch(evt.key) {
          case 'ArrowLeft':
            moveToPrevious(target);
            break;
          case 'ArrowUp':
            moveToPrevious(target);
            break;
          case 'ArrowDown':
            moveToNext(target);
            break;
          case 'ArrowRight':
            moveToNext(target);
            break;
        }
      }
    }
  }
  _savedCaretPosition = null;

  if (evt.key === 'Enter' && !evt.shiftKey) {
    return;
  }
  if([32, 37, 38, 39, 40].indexOf(evt.keyCode) > -1){ 
    return;
  } 
  var sentence_id = target.id.split("_")[1]
  var body = target.innerHTML.trim()
  debouncedUpdate(body, sentence_id)
}

function isArrowMovement(key) {
  return ["ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown"].indexOf(key) > -1
}

function getCaretLocation(key, target) {
  return key + ' ' + getCaretIndex(target) + ' ' + target.innerText.length + ' ' + getCaretLine() + ' ' + getLineCount(target);
}

function moveToPrevious(el) {
  var prev = el.previousElementSibling;
  if (prev && prev.className == "sentence-input") {
    placeCaretAtEnd(prev);
  }
}

function moveToNext(el) {

  var next = el.nextElementSibling;
  if (next && next.className == "sentence-input") {
    next.focus();
  }
}

function placeCaretAtEnd(el) {
  el.focus();
  if (typeof window.getSelection != "undefined"
          && typeof document.createRange != "undefined") {
      var range = document.createRange();
      range.selectNodeContents(el);
      range.collapse(false);
      var sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
  } else if (typeof document.body.createTextRange != "undefined") {
      var textRange = document.body.createTextRange();
      textRange.moveToElementText(el);
      textRange.collapse(false);
      textRange.select();
  }
}

function getCaretIndex(element) {
  let position = 0;
  const isSupported = typeof window.getSelection !== "undefined";
  if (isSupported) {
    const selection = window.getSelection();
    if (selection.rangeCount !== 0) {
      const range = window.getSelection().getRangeAt(0);
      const preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(element);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      position = preCaretRange.toString().length;
    }
  }
  return position;
}

function getCaretLine() {
  var sel = document.getSelection(),
        nd = sel.anchorNode,
        text = nd.textContent.slice(0, sel.focusOffset);

  var line = text.split("\n").length;
  return line;
}

function getLineCount(element) {
  return element.textContent.split("\n").length;
}

var debouncedUpdate = debounce(function (body, sentence_id) {
  formSubmit({command: 'update', body: body, sentence_id: sentence_id })
}, 200);

var debouncedPartial = debounce(function (text) {
  formSubmit({command: 'partial', body: text})
}, 400);

var debouncedManual = debounce(function (command, body) {
  formSubmit({command: command, body: body, mode: 'manual' })
}, 200);

function pauseUpdates() {
  _pauseUpdates = true;

  setTimeout(() => {
    _pauseUpdates = false;
  }, 500);  
}

ready(() => {
  window.formSubmit = formSubmit;
  window._publisherId = _publisherId;

  var btn = document.getElementById('startCaption');
  var checkPublish = document.getElementById('is-publish-page');
  if (checkPublish) {
    addLiveEventListeners(".sentence-input", "keyup", handleKeyUp );
    addLiveEventListeners(".sentence-input", "keydown", handleKeyDown );

    _publisherId = localStorage.getItem('_publisherId');
    if (_publisherId === null) {
      _publisherId = createUUID();
      localStorage.setItem('_publisherId', _publisherId);
    }
  }

  if(btn) {
    // only load these on the publisher page
    if (!window.audioInactive) {
      listDevices();
    }
    
    var manualInput = document.getElementById('manualInput');

    btn.onclick = async () => {
      console.log('startCaption clicked')
      toggleMicrophone(btn);
    }

    manualInput.addEventListener('keydown', (e) => {
      if (e.key === "Enter") {
        formSubmit({command: 'final', body: manualInput.innerHTML, mode: 'manual' })
        manualInput.innerHTML = '';
        e.preventDefault();
      } 
    })

    manualInput.addEventListener('change', (e) => {
      console.log('size', manualInput.offsetHeight);
    });

    docTitle.addEventListener('keyup', ({key}) => {
      formSubmit({command: 'title', body: docTitle.value })
    });

    docTitle.addEventListener('keydown', (e) => {
      if (e.key === "Enter") {
        e.preventDefault()
      }
    })

    manualInput.innerHTML = '';

    var manualInterval = window.setInterval(function(){
      if (manualVisible) {
        var size = manualInput.offsetHeight;
        
        if (_currentManual != manualInput.innerHTML) {
          document.getElementById('scrollable').style.paddingBottom = (size + 27) + "px"
          _currentManual = manualInput.innerHTML
          formSubmit({command: 'partial', body: manualInput.innerHTML, mode: 'manual' })
        }
      }
    }, 200);

    document.addEventListener('keydown', e => {
      if (e.code == "KeyS" && e.shiftKey == true && e.ctrlKey == true) {
        console.log('shift+ctrl+s');
        toggleMicrophone(btn);
        e.preventDefault();
      }
    });

    document.getElementById('saveLanguages').onclick = function(e) {
      const selected = document.querySelectorAll('#languageSelect option:checked');
      const values = Array.from(selected).map(el => el.value);
      const audioselected = document.querySelectorAll('#languageSelectAudio option:checked');
      const audiovalues = Array.from(audioselected).map(el => el.value);
      const aiselected = document.querySelectorAll('#languageSelectAi option:checked');
      const aivalues = Array.from(aiselected).map(el => el.value);
      const sourcelang = document.getElementById('sourceLanguage').value;
      formSubmit({command: 'languages', 
        languages: values, 
        audiovalues: audiovalues,
        aivalues: aivalues,
        sourcelang: sourcelang,
      })

      var myModalEl = document.getElementById('languageModal');
      var modal = bootstrap.Modal.getInstance(myModalEl)
      modal.hide();
    };
  } else { // end publisher only
    const objDiv = document.getElementById("stream-wrapper")
    objDiv.style.overflow = 'scroll'
    scrollToBottom();
  }

  var autoScroll = document.getElementById('autoScroll');

  if (autoScroll) {
    //and only trigger these when we are on a publisher or stream page
    cable.subscribeTo({ channel: 'UpdatesChannel', room: globalRoomId }, { received: handleUpdate })

    document.getElementById('autoScroll').addEventListener("change", () => {
      if (document.getElementById('autoScroll').checked) {
        scrollToBottom();
      }
    });
    scrollToBottom();
  }

  var partialInput = document.getElementById('partial');

  partialInput.addEventListener('keydown', (e) => {
    pauseUpdates();

    if (e.key.length == 1 || e.key === 'Enter' || e.key === 'Backspace') {
      // this is a character or some kind of key
      formSubmit({
        command: 'partial_edit', 
        position: window.getSelection().anchorOffset,
        range: window.getSelection().focusOffset,
        key: e.key })
    } else {
      console.log('rejected', e.key)
    }
  })

  partialInput.addEventListener('click', (e) => {
    pauseUpdates();
  });
})

